<template>
  <!-- edit mode -->
  <div v-if="editMode">
    <div v-if="$vuetify.breakpoint.smAndUp && !onlyDialog">
      <v-menu
        max-width="500px"
        origin="center center"
        :close-on-content-click="false"
        :nudge-bottom="menuNudgeBottom"
        transition="scale-transition"
        :maxHeight="`${menuMaxHeight}`"
        :offset-y="offsetY"
        ref="popupWindow"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on" @click="onActive()">
            <slot name="activator"></slot>
          </div>
        </template>
        <v-list :class="{ 'p-0': noPadding }">
          <v-list-item :class="{ 'p-0': noPadding }">
            <slot name="content"></slot>
          </v-list-item>
          <v-list-item v-if="showActionButtons">
            <div class="d-flex justify-end w-100">
              <v-btn
                text
                class="mr-2"
                @click="$refs.popupWindow.isActive = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="success"
                @click="submit('popup', $event)"
                :loading="loading"
                :disabled="!allowSubmit"
              >
                Save
              </v-btn>
            </div>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div v-else>
      <div @click="openDialog(true)">
        <slot name="activator"></slot>
      </div>
      <v-dialog v-model="dialogOpened" max-width="700px">
        <v-card>
          <v-card-title class="headline" primary-title>
            {{ title }}
          </v-card-title>
          <v-card-text :class="{ 'p-0': noPadding }">
            <slot name="content"></slot>
          </v-card-text>
          <v-card-actions
            v-if="showActionButtons"
            :class="{ 'p-0': noPadding }"
          >
            <v-spacer></v-spacer>
            <v-btn text class="mr-2" @click="openDialog(false)">
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="submit('dialog', $event)"
              :loading="loading"
              :disabled="!allowSubmit"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
  <!-- content mode -->
  <div v-else>
    <slot name="activator"></slot>
  </div>
</template>
<script>
export default {
  name: "PopupToDialogAdapter",
  props: {
    title: {
      required: true,
      type: String,
      default: ""
    },
    editMode: {
      required: true,
      type: Boolean,
      default: false
    },
    allowSubmit: {
      required: false,
      type: Boolean,
      default: true
    },
    onlyDialog: {
      required: false,
      type: Boolean,
      default: false
    },
    showActionButtons: {
      required: false,
      type: Boolean,
      default: true
    },
    menuNudgeBottom: {
      required: false,
      type: Number,
      default: 5
    },
    menuMaxHeight: {
      required: false,
      type: Number,
      default: 500
    },
    noPadding: {
      required: false,
      type: Boolean,
      default: false
    },
    offsetY: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      dialogOpened: false,
      loading: false
    };
  },
  computed: {},
  methods: {
    openDialog(state) {
      this.dialogOpened = state;
      this.$emit("open", this);
    },
    onActive() {
      this.$emit("open", this);
    },
    close() {
      if (this.$vuetify.breakpoint.smAndUp && !this.onlyDialog) {
        this.$refs.popupWindow.isActive = false;
      } else {
        this.dialogOpened = false;
      }
      this.loading = false;
    },
    submit(type, event) {
      event.preventDefault();
      if (!this.allowSubmit) {
        return false;
      }
      this.loading = true;
      this.$emit("submit");
    }
  }
};
</script>
<style lang="scss" scoped>
// styles
</style>
