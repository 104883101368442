var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":_vm.flat}},[_c('campaign-image',{attrs:{"src":_vm.campaign.image_url,"editMode":_vm.editMode,"campaign":_vm.campaign}}),_c('v-divider'),_c('v-card-text',[_c('v-container',{staticClass:"p-0",attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('popup-to-dialog-adapter',{ref:"campaignNameAdapter",attrs:{"title":"Campaign name","allowSubmit":_vm.campaignCopy.name &&
                _vm.campaignCopy.name.length >= 10 &&
                _vm.campaignCopy.name.length <= 64,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges('name', _vm.campaignCopy.name)},"open":function($event){return _vm.updateCopyValue($event, 'name', _vm.campaign.name)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"p-2 transition d-flex flex-column",class:{
                  'elevation-2': _vm.hoverIndex === 1 && _vm.editMode,
                  'cursor-pointer': _vm.editMode,
                },on:{"mouseover":function($event){_vm.hoverIndex = 1},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('h3',{staticClass:"headline mb-0 primary--text"},[_vm._v(" "+_vm._s(_vm.campaign.name)+" ")]),_c('div',[_c('v-img',{staticClass:"rounded-sm",attrs:{"width":"30","src":'/img/flags/' + _vm.campaign.country.flag,"alt":_vm.campaign.country.name}})],1)])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"campaign-edit-name d-flex w-100 p-2"},[_c('v-form',{staticClass:"w-100",on:{"submit":function($event){return _vm.$refs.campaignNameAdapter.submit(
                      _vm.$vuetify.breakpoint.smAndUp ? 'popup' : 'dialog',
                      $event
                    )}}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('min:10|max:64'),expression:"'min:10|max:64'"}],attrs:{"label":"Campaign name","hint":"Min. 10 characters","persistent-hint":"","counter":64,"data-vv-name":"Campaign name","error-messages":_vm.errors.collect('Campaign name'),"data-vv-validate-on":"blur"},model:{value:(_vm.campaignCopy.name),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "name", $$v)},expression:"campaignCopy.name"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('template',{slot:"append"},[_c('emoji-picker',{attrs:{"close-on-content-click":""},on:{"emoji":function($event){_vm.campaignCopy.name += $event}}})],1):_vm._e()],2)],1)],1)]},proxy:true}])}),(!_vm.isBusiness && !_vm.isInfluencer)?_c('div',{staticClass:"d-flex p-2 pb-0 accent--text"},[_c('router-link',{attrs:{"to":{
                name: 'business',
                params: { id: _vm.campaign.business.uuid },
              }}},[_c('span',[_vm._v(" "+_vm._s(_vm.campaign.business.display_name)+" ")])])],1):(_vm.isInfluencer)?_c('div',{staticClass:"d-flex p-2 pb-0"},[_c('span',[_vm._v(" "+_vm._s(_vm.campaign.business.display_name)+" ")])]):_vm._e()],1),_c('v-flex',{staticClass:"campaign-text primary--text",attrs:{"lg9":"","md8":"","sm8":"","xs12":""}},[(_vm.campaign.business.description)?_c('v-flex',{staticClass:"mb-3 p-0"},[_c('div',{staticClass:"headline pl-2"},[_vm._v("About")]),_c('popup-to-dialog-adapter',{attrs:{"title":"About","onlyDialog":true,"allowSubmit":_vm.stripHtmlTags(_vm.businessDescription).length >= 100 &&
                  _vm.stripHtmlTags(_vm.businessDescription).length <= 1000,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveBusinessDescription()},"open":function($event){return _vm.openBusinessDescription($event)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"p-2 transition",class:{
                    'elevation-2': _vm.hoverIndex === 2 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 2},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('pre',{staticClass:"body-1 d-block",domProps:{"innerHTML":_vm._s(_vm.campaign.business.description)}})])]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{staticClass:"p-0"},[_c('simple-rich-text-input',{ref:"businessDescriptionRef",attrs:{"height":200,"minlength":100,"maxlength":1000,"textStrength":{
                      mid: 282,
                      high: 413,
                    }},model:{value:(_vm.businessDescription),callback:function ($$v) {_vm.businessDescription=$$v},expression:"businessDescription"}})],1)]},proxy:true}],null,false,1799026469)})],1):_vm._e(),(_vm.campaign.product_description)?_c('v-flex',{staticClass:"mb-3 p-0"},[_c('div',{staticClass:"headline pl-2"},[_vm._v("Product and target audience")]),_c('popup-to-dialog-adapter',{attrs:{"title":"Product and target audience","onlyDialog":true,"allowSubmit":_vm.stripHtmlTags(_vm.campaignCopy.product_description).length >=
                  100 &&
                  _vm.stripHtmlTags(_vm.campaignCopy.product_description).length <=
                    550,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges(
                  'product_description',
                  _vm.campaignCopy.product_description
                )},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'product_description',
                  _vm.campaign.product_description,
                  'productDescriptionRef'
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"p-2 transition",class:{
                    'elevation-2': _vm.hoverIndex === 3 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 3},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('pre',{staticClass:"body-1 d-block",domProps:{"innerHTML":_vm._s(_vm.campaign.product_description)}})])]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{staticClass:"p-0"},[_c('simple-rich-text-input',{ref:"productDescriptionRef",attrs:{"height":200,"minlength":100,"maxlength":550,"textStrength":{
                      mid: 217,
                      high: 384,
                    }},model:{value:(_vm.campaignCopy.product_description),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "product_description", $$v)},expression:"campaignCopy.product_description"}})],1)]},proxy:true}],null,false,2550189363)})],1):_vm._e(),(_vm.campaign.promotion_material_description)?_c('v-flex',{staticClass:"mb-3 p-0"},[_c('div',{staticClass:"headline pl-2"},[_vm._v("What do you get")]),_c('popup-to-dialog-adapter',{attrs:{"title":"Hvad får du","onlyDialog":true,"allowSubmit":_vm.stripHtmlTags(_vm.campaignCopy.promotion_material_description)
                  .length >= 100 &&
                  _vm.stripHtmlTags(_vm.campaignCopy.promotion_material_description)
                    .length <= 550,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges(
                  'promotion_material_description',
                  _vm.campaignCopy.promotion_material_description
                )},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'promotion_material_description',
                  _vm.campaign.promotion_material_description,
                  'promotionDescriptionRef'
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"p-2 transition",class:{
                    'elevation-2': _vm.hoverIndex === 4 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 4},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('pre',{staticClass:"body-1 d-block",domProps:{"innerHTML":_vm._s(_vm.campaign.promotion_material_description)}})])]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{staticClass:"pl-2"},[_c('simple-rich-text-input',{ref:"promotionDescriptionRef",attrs:{"height":200,"minlength":100,"maxlength":550,"textStrength":{
                      mid: 217,
                      high: 335,
                    }},model:{value:(_vm.campaignCopy.promotion_material_description),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "promotion_material_description", $$v)},expression:"campaignCopy.promotion_material_description"}})],1)]},proxy:true}],null,false,91815055)})],1):_vm._e(),(_vm.campaign.recommendations_description)?_c('v-flex',{staticClass:"mb-3 p-0"},[_c('div',{staticClass:"headline pl-2"},[_vm._v("Recommendations")]),_c('popup-to-dialog-adapter',{attrs:{"title":"Recommendations","onlyDialog":true,"allowSubmit":_vm.stripHtmlTags(_vm.campaignCopy.recommendations_description)
                  .length >= 100 &&
                  _vm.stripHtmlTags(_vm.campaignCopy.recommendations_description)
                    .length <= 550,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges(
                  'recommendations_description',
                  _vm.campaignCopy.recommendations_description
                )},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'recommendations_description',
                  _vm.campaign.recommendations_description,
                  'recommendationsDescriptionRef'
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"p-2 transition",class:{
                    'elevation-2': _vm.hoverIndex === 5 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 5},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('pre',{staticClass:"body-1 d-block",domProps:{"innerHTML":_vm._s(_vm.campaign.recommendations_description)}})])]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{staticClass:"p-0"},[_c('simple-rich-text-input',{ref:"recommendationsDescriptionRef",attrs:{"height":200,"minlength":100,"maxlength":550,"textStrength":{
                      mid: 175,
                      high: 227,
                    }},model:{value:(_vm.campaignCopy.recommendations_description),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "recommendations_description", $$v)},expression:"campaignCopy.recommendations_description"}})],1)]},proxy:true}],null,false,2286875341)})],1):_vm._e(),(_vm.campaign.description)?_c('v-flex',{staticClass:"mb-3 p-0"},[(_vm.campaign.business.description || _vm.hasMultipleDescriptions)?_c('div',{staticClass:"headline pl-2"},[_vm._v(" General ")]):_vm._e(),_c('popup-to-dialog-adapter',{attrs:{"title":"General","onlyDialog":true,"allowSubmit":_vm.stripHtmlTags(_vm.campaignCopy.description).length >= 100 &&
                  _vm.stripHtmlTags(_vm.campaignCopy.description).length <= 1000,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges('description', _vm.campaignCopy.description)},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'description',
                  _vm.campaign.description,
                  'defaultDescriptionRef'
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('div',{staticClass:"p-2 transition",class:{
                    'elevation-2': _vm.hoverIndex === 6 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 6},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('pre',{staticClass:"body-1 d-block",domProps:{"innerHTML":_vm._s(_vm.campaign.description)}})])]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{staticClass:"p-0"},[_c('simple-rich-text-input',{ref:"defaultDescriptionRef",attrs:{"height":200,"minlength":100,"maxlength":1000,"textStrength":{
                      mid: 282,
                      high: 413,
                    }},model:{value:(_vm.campaignCopy.description),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "description", $$v)},expression:"campaignCopy.description"}})],1)]},proxy:true}],null,false,3710648423)})],1):_vm._e()],1),_c('v-flex',{attrs:{"lg3":"","md4":"","sm4":""}},[_c('v-list',{staticClass:"p-0",attrs:{"tile":"","dense":""}},[_c('v-subheader',{staticClass:"light-grey"},[_vm._v("Dates")]),_c('popup-to-dialog-adapter',{attrs:{"title":"Start","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator || (_vm.isBusiness && _vm.campaign.starting_at > _vm.minDate)},on:{"submit":function($event){return _vm.saveEditChanges('starting_at', _vm.campaignCopy.starting_at)},"open":function($event){return _vm.updateCopyValue($event, 'starting_at', _vm.campaign.starting_at)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 7 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 7},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-calendar-plus")])],1),_c('v-list-item-content',[(_vm.campaign.starting_at)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("utc_to_local_datetime")(_vm.campaign.starting_at,"D MMMM YYYY"))+" ")]):_c('v-list-item-title',[_vm._v("-")]),_c('v-list-item-subtitle',[_vm._v("Start")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('date-picker',{attrs:{"value":_vm.startingAt,"label":"Start date","min-date":_vm.minDate,"max-date":_vm.maxDate,"disabled":false},on:{"change":_vm.startDateChange}})]},proxy:true}])}),_c('popup-to-dialog-adapter',{attrs:{"title":"End","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator ||
                  (_vm.isBusiness && _vm.activeCampaigns <= _vm.allowedActiveCampaigns)},on:{"submit":function($event){return _vm.saveEditChanges('ending_at', _vm.campaignCopy.ending_at)},"open":function($event){return _vm.updateCopyValue($event, 'ending_at', _vm.campaign.ending_at)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 8 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 8},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-calendar-minus")])],1),_c('v-list-item-content',[(_vm.campaign.ending_at)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("utc_to_local_datetime")(_vm.campaign.ending_at,"D MMMM YYYY"))+" ")]):_c('v-list-item-title',[_vm._v("-")]),_c('v-list-item-subtitle',[_vm._v("Completed")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('date-picker',{attrs:{"value":_vm.endingAt,"label":"End date","min-date":_vm.isModerator ? _vm.minDate : _vm.minEndDate,"max-date":_vm.maxDate,"disabled":false},on:{"change":_vm.endDateChange}})]},proxy:true}])}),_c('v-subheader',{staticClass:"light-grey"},[_vm._v("Visibility")]),_c('popup-to-dialog-adapter',{attrs:{"title":"Landing page","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator},on:{"submit":function($event){return _vm.saveEditChanges('hidden', _vm.campaignCopy.hidden)},"open":function($event){return _vm.updateCopyValue($event, 'hidden', _vm.campaignCopy.hidden)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{class:{
                    'elevation-2': _vm.hoverIndex === 'isHidden' && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 'isHidden'},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[(_vm.campaignCopy.hidden)?_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-eye-slash")]):_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-eye")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.campaignCopy.hidden ? "Hidden" : "Public")+" ")]),_c('v-list-item-subtitle',[_vm._v("Visibility")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-switch',{staticClass:"mt-1 menu-icon",attrs:{"label":"Hidden"},model:{value:(_vm.campaignCopy.hidden),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "hidden", $$v)},expression:"campaignCopy.hidden"}})],1)]},proxy:true}])}),_c('v-subheader',{staticClass:"light-grey"},[_vm._v("Media")]),_c('v-list-item',{staticClass:"transition",class:{
                'elevation-2': _vm.hoverIndex === 9 && false,
                'cursor-pointer': false,
              },on:{"mouseover":function($event){_vm.hoverIndex = 9},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[(_vm.campaign.channel)?_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v(_vm._s(_vm.campaign.channel.icon))]):_vm._e()],1),_c('v-list-item-content',[(_vm.campaign.channel)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.campaign.channel.display_name)+" ")]):_c('v-list-item-title',[_vm._v("-")]),_c('v-list-item-subtitle',[_vm._v("Media")])],1)],1),_c('popup-to-dialog-adapter',{attrs:{"title":"Min. follower requirements","onlyDialog":false,"allowSubmit":true,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges('min_followers', _vm.campaignCopy.min_followers)},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'min_followers',
                  _vm.campaign.min_followers
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 10 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 10},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-users")])],1),_c('v-list-item-content',[(_vm.campaign.min_followers)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm._f("local_numbers")(_vm.campaign.min_followers))+" ")]):_c('v-list-item-title',[_vm._v("No limit")]),_c('v-list-item-subtitle',[_vm._v("Min. follower requirements")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-select',{staticClass:"mb-3",attrs:{"label":"Min. follower requirements","items":_vm.minFollowersArray,"item-text":"label","return-object":false},model:{value:(_vm.campaignCopy.min_followers),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "min_followers", $$v)},expression:"campaignCopy.min_followers"}})]},proxy:true}])}),_c('v-subheader',{staticClass:"light-grey"},[_vm._v("Settings")]),_c('popup-to-dialog-adapter',{attrs:{"title":"Category","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator},on:{"submit":function($event){_vm.saveEditChanges(
                  'channel_category_id',
                  Number(_vm.tempCategory.id),
                  true
                )},"open":function($event){_vm.updateCopyValue(
                  $event,
                  'channel_category_id',
                  Number(_vm.campaign.channel_category_id)
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 11 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 11},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[(_vm.campaign.category)?_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v(_vm._s(_vm.campaign.category.icon))]):_vm._e()],1),_c('v-list-item-content',[(_vm.campaign.category)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.campaign.category.display_name)+" ")]):_c('v-list-item-title',[_vm._v("-")]),_c('v-list-item-subtitle',[_vm._v("Category")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('channel-category-select',{staticClass:"mt-4",attrs:{"returnObject":true,"hasIcons":true,"multiple":false},on:{"input":function($event){_vm.tempCategory = $event}},model:{value:(_vm.campaignCopy.category.id),callback:function ($$v) {_vm.$set(_vm.campaignCopy.category, "id", $$v)},expression:"campaignCopy.category.id"}})]},proxy:true}])}),_c('popup-to-dialog-adapter',{attrs:{"title":"Deep link","onlyDialog":false,"allowSubmit":true,"editMode":_vm.allowDeepLinking},on:{"submit":function($event){return _vm.saveEditChanges(
                  'allow_deep_linking',
                  _vm.campaignCopy.allow_deep_linking
                )},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'allow_deep_linking',
                  _vm.campaign.allow_deep_linking
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2':
                      _vm.hoverIndex === 12 && _vm.allowDeepLinking && _vm.editMode,
                    'cursor-pointer': _vm.allowDeepLinking && _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 12},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-external-link")])],1),_c('v-list-item-content',[(_vm.campaign.allow_deep_linking !== null)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.campaign.allow_deep_linking ? "Yes" : "No")+" ")]):_c('v-list-item-title',[_vm._v("-")]),_c('v-list-item-subtitle',[_vm._v("Deep link")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-switch',{attrs:{"label":"Option to link to subpage","disabled":!_vm.allowDeepLinking},model:{value:(_vm.campaignCopy.allow_deep_linking),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "allow_deep_linking", $$v)},expression:"campaignCopy.allow_deep_linking"}})]},proxy:true}])}),_c('popup-to-dialog-adapter',{attrs:{"title":"Landing page","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator},on:{"submit":function($event){return _vm.saveEditChanges('url', _vm.redirectUrl)},"open":function($event){return _vm.updateCopyValue($event, 'url', _vm.campaign.url)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{class:{
                    'elevation-2': _vm.hoverIndex === 13 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 13},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("language")])],1),_c('v-list-item-content',[(_vm.campaign.url)?_c('v-list-item-title',[_c('a',{attrs:{"href":_vm.campaign.url,"target":"_blank"}},[_vm._v(" See page ")])]):_c('v-list-item-title',[_vm._v("-")]),_c('v-list-item-subtitle',[_vm._v("Landing page")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-select',{staticClass:"mb-2",attrs:{"label":"Choose landing page","items":[
                      {
                        text: _vm.stripProtocol(_vm.campaign.business.website),
                        value: _vm.stripProtocol(_vm.campaign.business.website),
                      },
                      { text: 'User defined', value: 'custom' } ]},on:{"change":_vm.selectLandingUrl},model:{value:(_vm.landingSelectValue),callback:function ($$v) {_vm.landingSelectValue=$$v},expression:"landingSelectValue"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayRedirectInput),expression:"displayRedirectInput"},{name:"validate",rawName:"v-validate",value:({
                      required: true,
                      business_website_match: true,
                    }),expression:"{\n                      required: true,\n                      business_website_match: true,\n                    }"}],staticClass:"mb-2",attrs:{"label":"Insert link","persistent-hint":"","hint":"The website URL customers are refered to","data-vv-scope":"redirect_url","data-vv-name":"redirect_url","data-vv-as":"URL'en","error-messages":_vm.errors.collect('redirect_url.redirect_url')},model:{value:(_vm.redirectUrl),callback:function ($$v) {_vm.redirectUrl=$$v},expression:"redirectUrl"}})],1)]},proxy:true}])}),(!_vm.isInfluencer)?_c('popup-to-dialog-adapter',{attrs:{"title":"Landing page","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator || _vm.isBusiness},on:{"submit":function($event){return _vm.saveEditChanges('utm_campaign', _vm.campaignCopy.utm_campaign)},"open":function($event){return _vm.updateCopyValue($event, 'utm_campaign', _vm.campaign.utm_campaign)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{class:{
                    'elevation-2': _vm.hoverIndex === 'utmCampaign' && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 'utmCampaign'},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-link")])],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.campaign.utm_campaign)?[_vm._v(_vm._s(_vm.campaign.utm_campaign))]:_c('i',[_vm._v("X")])],2),_c('v-list-item-title',[_vm._v("utm campaign")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Campaign utm","persistent-hint":"","hint":"","data-vv-scope":"utm_campaign","data-vv-name":"utm_campaign","data-vv-as":"URL'en"},model:{value:(_vm.campaignCopy.utm_campaign),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "utm_campaign", $$v)},expression:"campaignCopy.utm_campaign"}})],1)]},proxy:true}],null,false,401330567)}):_vm._e(),(!_vm.isInfluencer)?_c('popup-to-dialog-adapter',{attrs:{"title":"Landing page","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator || _vm.isBusiness},on:{"submit":function($event){return _vm.saveEditChanges('utm_medium', _vm.campaignCopy.utm_medium)},"open":function($event){return _vm.updateCopyValue($event, 'utm_medium', _vm.campaign.utm_medium)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{class:{
                    'elevation-2': _vm.hoverIndex === 'utmMedium' && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 'utmMedium'},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-link")])],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.campaign.utm_medium)?[_vm._v(_vm._s(_vm.campaign.utm_medium))]:_c('i',[_vm._v("X")])],2),_c('v-list-item-title',[_vm._v("utm medium field")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Medium utm","persistent-hint":"","hint":"","data-vv-scope":"utm_medium","data-vv-name":"utm_medium","data-vv-as":"URL'en"},model:{value:(_vm.campaignCopy.utm_medium),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "utm_medium", $$v)},expression:"campaignCopy.utm_medium"}})],1)]},proxy:true}],null,false,2549258406)}):_vm._e(),(!_vm.isInfluencer)?_c('popup-to-dialog-adapter',{attrs:{"title":"Landing page","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator || _vm.isBusiness},on:{"submit":function($event){return _vm.saveEditChanges('utm_source', _vm.campaignCopy.utm_source)},"open":function($event){return _vm.updateCopyValue($event, 'utm_source', _vm.campaign.utm_source)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{class:{
                    'elevation-2': _vm.hoverIndex === 'utmSource' && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 'utmSource'},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-link")])],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.campaign.utm_source)?[_vm._v(_vm._s(_vm.campaign.utm_source))]:_c('i',[_vm._v("X")])],2),_c('v-list-item-title',[_vm._v("utm source field")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Source utm","persistent-hint":"","hint":"","data-vv-scope":"utm_source","data-vv-name":"utm_source","data-vv-as":"URL'en"},model:{value:(_vm.campaignCopy.utm_source),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "utm_source", $$v)},expression:"campaignCopy.utm_source"}})],1)]},proxy:true}],null,false,572859334)}):_vm._e(),_c('v-subheader',{staticClass:"light-grey"},[_vm._v("Payment")]),(_vm.campaign.cost_per_action_percent)?_c('popup-to-dialog-adapter',{attrs:{"title":"Per sale","onlyDialog":false,"allowSubmit":true,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges(
                  'cost_per_action_percent',
                  _vm.campaignCopy.cost_per_action_percent
                )},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'cost_per_action_percent',
                  _vm.campaign.cost_per_action_percent
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 14 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 14},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-percent")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.campaign.cost_per_action_percent)+"%")]),_c('v-list-item-subtitle',[_vm._v("Per sale")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{attrs:{"column":""}},[_c('div',{staticClass:"title text-center"},[_vm._v(" "+_vm._s(_vm.campaignCopy.cost_per_action_percent)+"% per sale ")]),_c('v-slider',{attrs:{"min":_vm.campaign.cost_per_action_percent,"max":100},model:{value:(_vm.campaignCopy.cost_per_action_percent),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "cost_per_action_percent", $$v)},expression:"campaignCopy.cost_per_action_percent"}})],1)]},proxy:true}],null,false,296680633)}):_vm._e(),(_vm.campaign.cost_per_action_fixed)?_c('popup-to-dialog-adapter',{attrs:{"title":"Per sale/lead","onlyDialog":false,"allowSubmit":true,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges(
                  'cost_per_action_fixed',
                  _vm.campaignCopy.cost_per_action_fixed
                )},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'cost_per_action_fixed',
                  _vm.campaign.cost_per_action_fixed
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 15 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 15},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-user-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.campaign.cost_per_action_fixed)+"kr.")]),_c('v-list-item-title',[_vm._v("Per sale/lead")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{attrs:{"column":""}},[_c('div',{staticClass:"title text-center"},[_vm._v(" "+_vm._s(_vm.campaignCopy.cost_per_action_fixed)+"kr. per sale ")]),_c('v-slider',{attrs:{"min":_vm.campaign.cost_per_action_fixed,"max":1000},model:{value:(_vm.campaignCopy.cost_per_action_fixed),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "cost_per_action_fixed", $$v)},expression:"campaignCopy.cost_per_action_fixed"}})],1)]},proxy:true}],null,false,857240175)}):_vm._e(),(_vm.isInfluencer && _vm.campaign.cost_per_click)?_c('v-list-item',[_c('v-list-item-content',[_c('a',{attrs:{"href":"https://intercom.help/Make-influence-com/da/articles/6248423-hvad-er-en-klik-kampagne","target":"_blank"}},[_vm._v(" Read about click campaigns ")])])],1):_vm._e(),(_vm.campaign.cost_per_click)?_c('popup-to-dialog-adapter',{attrs:{"title":"Per click","onlyDialog":false,"allowSubmit":true,"editMode":_vm.editMode},on:{"submit":function($event){return _vm.saveEditChanges('cost_per_click', _vm.campaignCopy.cost_per_click)},"open":function($event){return _vm.updateCopyValue(
                  $event,
                  'cost_per_click',
                  _vm.campaign.cost_per_click
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 15 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 15},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-hand-point-up")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.campaign.cost_per_click)+"kr.")]),_c('v-list-item-title',[_vm._v("Per click")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{attrs:{"column":""}},[_c('div',{staticClass:"title text-center"},[_vm._v(" "+_vm._s(_vm.campaignCopy.cost_per_click)+"kr. per click ")]),_c('v-slider',{attrs:{"min":_vm.campaign.cost_per_click,"max":100},model:{value:(_vm.campaignCopy.cost_per_click),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "cost_per_click", $$v)},expression:"campaignCopy.cost_per_click"}})],1)]},proxy:true}],null,false,532808716)}):_vm._e(),(_vm.campaign.max_commission_budget)?_c('popup-to-dialog-adapter',{attrs:{"title":"Max. budget per. participant","onlyDialog":false,"allowSubmit":true,"editMode":_vm.editMode},on:{"submit":function($event){_vm.saveEditChanges(
                  'max_commission_budget',
                  parseFloat(_vm.campaignCopy.max_commission_budget)
                )},"open":function($event){_vm.updateCopyValue(
                  $event,
                  'max_commission_budget',
                  parseFloat(_vm.campaign.max_commission_budget)
                )}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 18 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 18},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-abacus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.campaign.max_commission_budget)+"kr.")]),_c('v-list-item-title',[_vm._v(" Max budget per participants ")])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-flex',{attrs:{"column":""}},[_c('small',{staticClass:"font-italic primary--text"},[_vm._v(" Doesn't change for existing participants ")]),_c('v-text-field',{attrs:{"type":"number","suffix":"kr."},model:{value:(_vm.campaignCopy.max_commission_budget),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "max_commission_budget", $$v)},expression:"campaignCopy.max_commission_budget"}})],1)]},proxy:true}],null,false,122188335)}):_vm._e(),_c('v-subheader',{staticClass:"light-grey"},[_vm._v("Contact person")]),_c('popup-to-dialog-adapter',{attrs:{"title":"Min. follower requirements","onlyDialog":false,"allowSubmit":true,"editMode":_vm.isModerator},on:{"submit":function($event){return _vm.saveEditChanges('agency_uuid', _vm.campaignCopy.agency_uuid)},"open":function($event){return _vm.updateCopyValue($event, 'agency_uuid', _vm.campaign.agency_uuid)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"transition",class:{
                    'elevation-2': _vm.hoverIndex === 16 && _vm.editMode,
                    'cursor-pointer': _vm.editMode,
                  },on:{"mouseover":function($event){_vm.hoverIndex = 16},"mouseout":function($event){_vm.hoverIndex = null}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mt-1 menu-icon"},[_vm._v("fal fa-user")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.contactPerson))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.contactEntity))])],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('agencies-select',{attrs:{"return-object":false,"clearable":""},model:{value:(_vm.campaignCopy.agency_uuid),callback:function ($$v) {_vm.$set(_vm.campaignCopy, "agency_uuid", $$v)},expression:"campaignCopy.agency_uuid"}})]},proxy:true}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }